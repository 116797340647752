import React from 'react';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';

import {
  email,
  inner,
  imageWrap,
  links,
  email2,
} from 'styles/pages/digitec/email.module.scss';

const Email1 = () => {
  return (
    <div className={classnames(email, email2, 'scr-digitect-wrap')}>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <div className={inner}>
        <div className={classnames(imageWrap, email2)}>
          <img
            src="https://wp.screver.com/wp-content/uploads/2023/08/digitec-email-2-inner.png"
            alt=""
          />

          <div className={classnames(links, email2)}>
            <a
              href="https://go.screver.com/survey?token=1b53592e-19ac-40d7-9ab9-6a2e34e6fe94&surveyItem=64cbc4735e8d6d76ad6ac913&value=0&lang=en"
              target="_blank"
              rel="noopener noreferrer"
            ></a>
            <a
              href="https://go.screver.com/survey?token=1b53592e-19ac-40d7-9ab9-6a2e34e6fe94&surveyItem=64cbc4735e8d6d76ad6ac913&value=0&lang=en"
              target="_blank"
              rel="noopener noreferrer"
            ></a>
            <a
              href="https://go.screver.com/survey?token=1b53592e-19ac-40d7-9ab9-6a2e34e6fe94&surveyItem=64cbc4735e8d6d76ad6ac913&value=0&lang=en"
              target="_blank"
              rel="noopener noreferrer"
            ></a>
            <a
              href="https://go.screver.com/survey?token=1b53592e-19ac-40d7-9ab9-6a2e34e6fe94&surveyItem=64cbc4735e8d6d76ad6ac913&value=0&lang=en"
              target="_blank"
              rel="noopener noreferrer"
            ></a>
            <a
              href="https://go.screver.com/survey?token=1b53592e-19ac-40d7-9ab9-6a2e34e6fe94&surveyItem=64cbc4735e8d6d76ad6ac913&value=0&lang=en"
              target="_blank"
              rel="noopener noreferrer"
            ></a>
            <a
              href="https://go.screver.com/survey?token=1b53592e-19ac-40d7-9ab9-6a2e34e6fe94&surveyItem=64cbc4735e8d6d76ad6ac913&value=0&lang=en"
              target="_blank"
              rel="noopener noreferrer"
            ></a>
            <a
              href="https://go.screver.com/survey?token=1b53592e-19ac-40d7-9ab9-6a2e34e6fe94&surveyItem=64cbc4735e8d6d76ad6ac913&value=0&lang=en"
              target="_blank"
              rel="noopener noreferrer"
            ></a>
            <a
              href="https://go.screver.com/survey?token=1b53592e-19ac-40d7-9ab9-6a2e34e6fe94&surveyItem=64cbc4735e8d6d76ad6ac913&value=0&lang=en"
              target="_blank"
              rel="noopener noreferrer"
            ></a>
            <a
              href="https://go.screver.com/survey?token=1b53592e-19ac-40d7-9ab9-6a2e34e6fe94&surveyItem=64cbc4735e8d6d76ad6ac913&value=0&lang=en"
              target="_blank"
              rel="noopener noreferrer"
            ></a>
            <a
              href="https://go.screver.com/survey?token=1b53592e-19ac-40d7-9ab9-6a2e34e6fe94&surveyItem=64cbc4735e8d6d76ad6ac913&value=0&lang=en"
              target="_blank"
              rel="noopener noreferrer"
            ></a>
            <a
              href="https://go.screver.com/survey?token=1b53592e-19ac-40d7-9ab9-6a2e34e6fe94&surveyItem=64cbc4735e8d6d76ad6ac913&value=0&lang=en"
              target="_blank"
              rel="noopener noreferrer"
            ></a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Email1;
